.linksoft_single-service_main {
  width: 70%;
  margin: 2rem auto;
  display: grid;
  gap: 2rem;
}
.linksoft_single-service_title {
  font-size: 3rem;
  font-weight: 600;
  color: var(--color-navy);
}
.linksoft_single-service {
  display: grid;
  gap: 3rem;
  border: 3px solid var(--color-navy);
  padding: 1rem;
  color: var(--color-darkblue);
  font-size: 1.1rem;
}

@media screen and (max-width: 650px) {
  .linksoft_single-service_main {
    width: 100%;
    margin: 0;
    display: grid;
    gap: 0rem;
  }
  .linksoft_single-service_title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    color: var(--color-navy);
    margin: 0 1rem;
  }
  .linksoft_single-service {
    display: grid;
    gap: 2rem;
    border: 3px solid var(--color-navy);
    margin: 1rem auto;
    padding: 1rem;
    color: var(--color-darkblue);
    font-size: 1.1rem;
    width: 90%;
    text-align: justify;
  }
}
