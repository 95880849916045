.recruitment_services {
  padding: 1rem;
  display: grid;
  gap: 0.8rem;
}

.recruitment_services_title {
  font-size: 2rem;
  font-weight: 600;
}

.recruitment_services_desc {
  font-size: 1.1rem;
}

.recruitment_services_cards-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.recruitment_services_card {
  border: 2px solid var(--color-gray4);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  gap: 0.5rem;
}

.recruitment_services_card_title {
  font-size: 0.9rem;
  font-weight: 600;
}

.recruitment_services_card_desc {
  font-size: 0.6rem;
}

.recruitment_services_card_btn {
  background: none;
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.recruitment_services_card:hover {
  border: 2px solid var(--color-gray1);
  background-color: var(--color-gray2);
}

@media screen and (min-width: 950px) {
  .recruitment_services {
    padding: 3rem;
    display: grid;
    text-align: center;
    gap: 0.8rem;
  }

  .recruitment_services_title {
    font-size: 2rem;
    font-weight: 600;
  }

  .recruitment_services_desc {
    font-size: 1.1rem;
    width: 70%;
    text-align: center;
    margin-inline: auto;
  }

  .recruitment_services_cards-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem;
    margin: 3rem 0;
  }

  .recruitment_services_card {
    border: 2px solid var(--color-gray4);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    gap: 0.5rem;
  }

  .recruitment_services_card_title {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .recruitment_services_card_desc {
    font-size: 0.6rem;
  }

  .recruitment_services_card_btn {
    background: none;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .recruitment_services_card:hover {
    border: 2px solid var(--color-gray1);
    background-color: var(--color-gray2);
  }
}
