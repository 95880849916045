.recruitment_servicehero {
    display: flex;
    background-color: var(--color-gray4);
    padding-top: 2rem;
  }
  
  .recruitment_servicehero_content {
    width: 55%;
    padding: 3rem 0 3rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .recruitment_servicehero_heading {
  
    color: var(--color-darkblue);
    font-size: 4rem;
    font-weight: 600;
    line-height: 5rem;
  }
  
  .recruitment_servicehero_desc {
    color: rgba(50, 20, 116, 0.90);
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-weight: 600;
  }
  
  .recruitment_servicehero_img-div {
    width: 45%;
    position: relative;
    display: flex;
    align-items: flex-end;
  }
  
  .recruitment_servicehero_img {
    width: 100%;
    margin-top: auto;
    z-index: 2;
  }
  
  .recruitment_servicehero_imgBg {
    width: 70%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  
  .recruitment_servicehero_search_main {
    display: flex;
    align-items: center;
    width: 100%;
  
  }
  
  
  .recruitment_servicehero_search_main button {
    background-color: var(--color-darkblue);
    width: 10rem;
    height: 4rem;
    border-radius: 4px;
    color: var(--color-white);
    cursor: pointer;
  }
  
  @media screen and (max-width: 950px) {
  
    .recruitment_servicehero {
      flex-direction: column;
      gap: 2rem;
    }
  
    .recruitment_servicehero_content {
      width: 100%;
      padding: 1rem;
      gap: 1.5rem;
    }
  
    .recruitment_servicehero_heading {
      font-size: 2rem;
      line-height: 1.5;
    }
  
    .recruitment_servicehero_desc {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  
    .recruitment_servicehero_img-div {
      width: 100%;
    }
  
    .recruitment_servicehero_search_main {
      display: flex;
      align-items: center;
      width: 100%;
    }
  
    .recruitment_servicehero_search_div {
      height: 3rem;
    }
  
    .recruitment_servicehero_search_div input {
      font-weight: 600;
    }
  
  
    .recruitment_servicehero_search_main button {
      height: 3rem;
    }
  }
  
  @media screen and (max-width: 550px) {
  
    .recruitment_servicehero {
      flex-direction: column;
      gap: 2rem;
    }
  
    .recruitment_servicehero_content {
      width: 100%;
      padding: 1rem;
      gap: 1.5rem;
    }
  
    .recruitment_servicehero_heading {
      font-size: 2rem;
      line-height: 1.5;
    }
  
    .recruitment_servicehero_desc {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  
    .recruitment_servicehero_img-div {
      width: 100%;
    }
  
    .recruitment_servicehero_search_main {
      display: flex;
      align-items: center;
      width: 100%;
    }
  
    .recruitment_servicehero_search_div {
      height: 3rem;
    }
  
    .recruitment_servicehero_search_div input {
      font-weight: 600;
    }
  
  
    .recruitment_servicehero_search_main button {
      height: 3rem;
    }
  }