* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  list-style: none;
  font-family: var(--font-family);
}

body {
  background: var(--color-bg);

}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

a {
  color: unset;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 2000px) {
  body {
    max-width: 100rem;
    margin-inline: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}