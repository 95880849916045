.recruitment_testimonials {
    padding: 2rem;
}

.recruitment_testimonial_heading {
    font-size: 2rem;
    font-weight: 600;
}

.recruitment_testimonial_card {
    margin: 2rem 1rem;
    padding: 2rem;
    display: grid;
    gap: 1rem;
    background-color: var(--color-gray2);
    border-radius: 0.5rem;
}

.recruitment_testimonial_client-details_main {
    display: flex;
    justify-content: space-between;
}

.recruitment_testimonial_client-details {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.recruitment_testimonial_client_post {
    font-weight: 500;
    color: var(--color-gray1);
    font-size: 0.9rem;
}

.recruitment_testimonial_quote {
    font-size: 3rem;
    color: var(--color-gray3);
}

.recruitment_testimonial_client_desc {
    font-weight: 500;
    color: var(--color-gray1);
    font-size: 0.9rem;
    text-align: justify;
}

.recruitment_testimonial_client_rating {
    color: var(--color-gray1);
    font-size: 0.8rem;
}

.recruitment_testimonial_client_stars {
    color: var(--color-yellow);
}

@media screen and (min-width: 950px) {
    .recruitment_testimonials {
        padding: 2rem;
        text-align: center;

    }
    .carousel-container{
        margin: 0 2rem;
    }

    .recruitment_testimonial_heading {
        font-size: 3rem;
        font-weight: 600;
    }

    .recruitment_testimonial_desc {
        /* width: 60%; */
        margin: 0 10rem;
        text-align: center;
        font-size: 1.2rem;
    }

    .recruitment_testimonial_card {
        margin: 2rem 1rem;
        padding: 2rem;
        display: grid;
        gap: 1rem;
        background-color: var(--color-gray2);
        border-radius: 0.5rem;
    }

    .recruitment_testimonial_client-details_main {
        display: flex;
        justify-content: space-between;
    }

    .recruitment_testimonial_client-details {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .recruitment_testimonial_client_post {
        font-weight: 500;
        color: var(--color-gray1);
        font-size: 0.9rem;
    }

    .recruitment_testimonial_quote {
        font-size: 3rem;
        color: var(--color-gray3);
    }

    .recruitment_testimonial_client_desc {
        font-weight: 500;
        color: var(--color-gray1);
        font-size: 0.9rem;
        text-align: justify;
    }

    .recruitment_testimonial_client_rating {
        color: var(--color-gray1);
        font-size: 0.8rem;
    }

    .recruitment_testimonial_client_stars {
        color: var(--color-yellow);
    }

}