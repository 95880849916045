.linksoft_footer {
  font-family: var(--font-family);
  background-color: var(--color-darkblue);
  margin-top: auto;
  color: var(--color-gray2);
}

.linksoft_footer_logo_mobile {
  display: none;
}

.linksoft_footer-newsletter_container {
  padding: 8rem 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.linksoft_footer-desc {
  width: 50%;
  font-size: 1.25rem;
  line-height: 2rem;
}

.linksoft_footer-newsletter_input_div {
  width: 45%;
  border: 4px solid var(--color-white);
  background-color: var(--color-white);
  border-radius: 3rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 5rem;
}

.linksoft_footer-newsletter_input {
  width: 70%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0rem 1rem;
}

.linksoft_footer-newsletter_btn {
  border-radius: 3rem;
  background-color: var(--color-blue);
  color: var(--color-gray2);
  width: 30%;
  height: 100%;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 600;
}

.linksoft_footer-newsletter_input:focus {
  border: none;
  outline: none;
}

.linksoft_footer-links_main-div {
  display: grid;
  gap: 2rem;
}
.linksoft_footer-links_links-main-div {
  padding: 3rem 3.5rem;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  border-top: 1px solid var(--color-gray3);
}
.linksoft_footer_logo-link {
  width: 30%;
}
.linksoft_footer_logo {
  /* font-size: 3rem;
  font-weight: 600; */
  width: 95%;
  /* border: 2px solid red; */
}

.linksoft_footer-title {
  font-size: 2rem;
  font-weight: 600;
}

.linksoft_footer_div {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

/* 
.linksoft_footer-links_container {
  display: flex;
  font-size: 1.5rem;
  width: 60%;
  align-items: center;
  justify-content: space-between;
}

.linksoft_footer-links_social_div {
  display: flex;
  font-size: 2rem;
  align-items: center;
  gap: 1.5rem;
}
 */

.linksoft_footer-links_div {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.linksoft_footer-links_small-div {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* border: 2px solid red; */
}
.linksoft_footer-links {
  display: grid;
  gap: 1.5rem;
  font-size: 1.2rem;
}
.linksoft_footer_content {
  display: grid;
  gap: 1.5rem;
}

.linksoft_footer_social-main {
  display: grid;
  gap: 2rem;
}
.linksoft_footer_social-div {
  display: flex;
  align-items: center;
  position: relative;
  margin-inline-start: 2rem;
  /* width: fit-content; */
}

.linksoft_footer_social-div img {
  width: 23%;
  position: absolute;
  left: -2rem;
  border-radius: 50%;
}

.linksoft_footer_social-link-div {
  padding: 0.3rem;
  padding-inline-start: 1.5rem;
  width: 100%;
  background-color: var(--color-white);
  color: var(--color-gray1);
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  font-size: 0.5rem;
  /* font-weight: 600; */
}
.linksoft_footer_social-link-title {
  font-weight: 600;
  white-space: nowrap;
  font-size: 0.7rem;
  text-align: center;
}
.linksoft_footer_copyright-div {
  margin-inline: auto;
  background-color: var(--color-blue);
  padding: 1rem;
  text-align: center;
  width: 50%;
  /* border-radius: 2rem; */
  border-top-right-radius: 3rem;
  border-top-left-radius: 3rem;
  color: var(--color-darkblue);
  font-weight: 600;
}

@media screen and (max-width: 920px) {
  .linksoft_footer {
    font-family: var(--font-family);
    background-color: var(--color-darkblue);
    margin-top: auto;
    color: var(--color-gray2);
    padding: 2rem 1.2rem;
  }

  .linksoft_footer_logo_mobile img {
    width: 100%;
    display: block;
  }

  .linksoft_footer-newsletter_container {
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
    padding: 0;
  }

  .linksoft_footer-desc {
    width: 100%;
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .linksoft_footer-newsletter_input_div {
    width: 100%;
    border: 4px solid var(--color-white);
    background-color: var(--color-white);
    border-radius: 3rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 4rem;
  }

  .linksoft_footer-newsletter_input {
    width: 70%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0rem 1rem;
  }

  .linksoft_footer-newsletter_btn {
    border-radius: 3rem;
    background-color: var(--color-blue);
    color: var(--color-gray2);
    width: 30%;
    height: 100%;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .linksoft_footer-newsletter_input:focus {
    border: none;
    outline: none;
  }

  .linksoft_footer-links_main-div {
    padding: 0;
    border-top: none;
    flex-direction: column;
    margin: 2rem 0;
  }

  .linksoft_footer_logo {
    display: none;
  }

  .linksoft_footer-links_container {
    font-size: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .linksoft_footer-links_li {
    font-weight: 500;
  }

  .linksoft_footer-links_social_div {
    display: flex;
    font-size: 2rem;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    width: 100%;
    margin: 1rem 0;
  }
}

@media screen and (max-width: 650px) {
  .linksoft_footer {
    font-family: var(--font-family);
    background-color: var(--color-darkblue);
    margin-top: auto;
    color: var(--color-gray2);
    padding: 2rem 1.2rem;
  }

  .linksoft_footer_logo_mobile {
    width: 100%;
    display: block;
    margin: 1rem 0;
  }

  .linksoft_footer-newsletter_container {
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
    padding: 0;
  }

  .linksoft_footer-desc {
    width: 100%;
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .linksoft_footer-newsletter_input_div {
    width: 100%;
    border: 4px solid var(--color-white);
    background-color: var(--color-white);
    border-radius: 3rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 4rem;
  }

  .linksoft_footer-newsletter_input {
    width: 70%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0rem 1rem;
  }

  .linksoft_footer-newsletter_btn {
    border-radius: 3rem;
    background-color: var(--color-blue);
    color: var(--color-gray2);
    width: 30%;
    height: 100%;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .linksoft_footer-newsletter_input:focus {
    border: none;
    outline: none;
  }

  .linksoft_footer-links_main-div {
    padding: 0;
    border-top: none;
    flex-direction: column;
    margin: 2rem 0;
    /* border: 2px solid red; */
  }
  .linksoft_footer-links_links-main-div {
    flex-direction: column;
    gap: 2rem;
    padding: 0;
  }
  .linksoft_footer_logo {
    display: none;
  }

  .linksoft_footer-links_container {
    font-size: 1.2rem;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .linksoft_footer-links_li {
    font-weight: 500;
  }

  .linksoft_footer-links_social_div {
    display: flex;
    font-size: 2rem;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    width: 100%;
    margin: 1rem 0;
  }
  .linksoft_footer_copyright-div {
    margin-inline: auto;
    background-color: var(--color-blue);
    width: 100%;
    padding: 1rem;
    text-align: center;
    border-top-right-radius: 3rem;
    border-top-left-radius: 3rem;
    color: var(--color-darkblue);
    font-weight: 600;
    font-size: 0.6rem;
  }
  .linksoft_footer-links_small-div {
    width: 100%;
    gap: 1rem;
    /* border: 2px solid red; */
  }
  .linksoft_footer-links {
    display: grid;
    gap: 1rem;
    font-size: 1.2rem;
    /* border: 2px solid red; */
  }
  .linksoft_footer_content {
    display: grid;
    gap: 1.5rem;
    /* border: 2px solid rgb(255, 196, 0); */
    width: 100%;
  }

  .linksoft_footer_social-main {
    display: grid;
    gap: 2rem;
  }
  .linksoft_footer_social-div {
    display: flex;
    align-items: center;
    position: relative;
    margin-inline-start: 2rem;
    /* width: 60%; */
  }

  .linksoft_footer_social-div img {
    width: 20%;
    position: absolute;
    left: -2rem;
    border-radius: 50%;
  }

  .linksoft_footer_social-link-div {
    padding: 0.3rem;
    padding-inline-start: 2.5rem;
    width: 100%;
    background-color: var(--color-white);
    color: var(--color-gray1);
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    font-size: 0.7rem;
  }
  .linksoft_footer_social-link-title {
    font-weight: 600;
    white-space: nowrap;
  }
  .linksoft_footer-links_div {
    width: 100%;
    gap: 1rem;
  }
}
