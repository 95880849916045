.recruitment_right-job {
    padding: 0;
}

.recruitment_right-job_content {
    padding: 2rem;
    display: grid;
    gap: 1rem;
}

.recruitment_right-job_desc_div {
    display: grid;
    gap: 1rem;
}

.recruitment_right-job_desc {
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-darkblue);
    line-height: 1.625rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.recruitment_right-job_checkbox {
    font-size: 1.3rem;
}

.recruitment_right-job_title {
    font-size: 2rem;
    color: var(--color-darkblue);
    font-weight: 600;
}

.recruitment_right-job_img-div {
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.recruitment_right-job_img {
    width: 60%;
    margin-top: auto;
    z-index: 2;
}

.recruitment_right-job_imgBg {
    width: 70%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

@media screen and (min-width: 950px) {
    .recruitment_right-job {
        padding: 4rem;
        display: flex;
        flex-direction: row;

    }

    .recruitment_right-job_content {
        padding: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2.5rem;
        width: 60%;
    }

    .recruitment_right-job_desc_div {
        display: grid;
        gap: 1rem;
    }

    .recruitment_right-job_desc {
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--color-darkblue);
    }

    .recruitment_right-job_title {
        font-size: 3rem;
        color: var(--color-darkblue);
        font-weight: 600;
        line-height: 1;
    }

    .recruitment_right-job_img-div {
        width: 40%;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;

    }

    .recruitment_right-job_img {
        width: 80%;
        margin-top: auto;
        z-index: 2;
    }

    .recruitment_right-job_imgBg {
        width: 100%;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }


}