.recruitment_about {
    padding: 2rem 0;
}

.recruitment_about_content {
    padding: 2rem;
    display: grid;
    gap: 1rem;
}

.recruitment_about_desc {
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-darkblue);
}

.recruitment_about_title {
    font-size: 2rem;
    color: var(--color-darkblue);
    font-weight: 600;
}

.recruitment_about_btn {
    background-color: var(--color-darkblue);
    margin-inline-end: auto;
    padding: 1rem;
    color: var(--color-white);
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 1rem;
}

.recruitment_about_btn_arrow {
    font-size: 1.5rem;

}

.recruitment_about_img {
    width: 100%;
}

@media screen and (min-width: 950px) {
    .recruitment_about {
        padding: 4rem;
        display: flex;
        flex-direction: row-reverse;

    }

    .recruitment_about_content {
        padding: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
    }

    .recruitment_about_desc {
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--color-darkblue);
    }

    .recruitment_about_title {
        font-size: 3rem;
        color: var(--color-darkblue);
        font-weight: 600;
    }

    .recruitment_about_btn {
        background-color: var(--color-darkblue);
        margin-inline-end: auto;
        padding: 1rem 2rem;
        color: var(--color-white);
        display: flex;
        align-items: center;
        gap: 1rem;
        border-radius: 0.3rem;
        cursor: pointer;
        font-size: 1rem;
    }

    .recruitment_about_btn_arrow {
        font-size: 1.5rem;

    }

    .recruitment_about_img {
        width: 80%;
    }

}