.linksoft_contact2 {
  padding: 5rem 3rem;
  display: grid;
  gap: 4rem;
  /* border: 2px solid red; */
}

.linksoft_contact2-card-main {
  display: flex;
  /* border: 1px solid black; */
  border-radius: 1rem;
  justify-content: center;
  gap: 1rem;
  /* padding: 2rem; */
  width: auto;
  margin-inline: auto;
  /* border: 2px solid red; */
}

.linksoft_contact2-card-button {
  color: var(--color-white);
  background-color: var(--color-darkblue);
  padding: 1rem 3rem;
  /* max-width: 10rem; */
  border: none;
  border-radius: 0.2rem;
  margin-inline-start: auto;
  font-size: 1.2rem;
}

.linksoft_contact2-card-section1 {
  padding: 2rem;
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-inline: auto;
  background-color: var(--color-dark-yellow);
}

.linksoft_contact2-card-section1-content {
  padding: 2rem;
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-inline-start: auto;
  /* border: 2px solid red; */
}

.linksoft_contact2-card-section1 h6 {
  font-weight: 600 !important;
  font-size: 0.9rem !important;
}

.linksoft_contact2-card-section1_title {
  font-size: 6rem;
  font-weight: 600;
}

.linksoft_contact2-card-inputs {
  display: flex;
  gap: 2rem;
  width: 100%;
  /* border: 2px solid red; */
}

.linksoft_contact2-card-input {
  /* background-color: var(--color-gray2); */
  border: none;
  border-bottom: 1px solid var(--color-gray3);
  outline: none;
  padding: 0.5rem;
  /* border-radius: 0.5rem; */
  max-width: 100%;
  resize: none;
  font-size: 1.1rem;
  background: none;
}

.linksoft_contact2-card-email,
.linksoft_contact2-card-message {
  display: grid;
  gap: 1rem;
  width: 100%;
}

/* 
.linksoft_contact2-card-email-input {
  background-color: var(--color-gray2);
  border: none;
  outline: none;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
} */

.linksoft_contact2-card-section1 h6 {
  font-size: 1rem;
  color: black;
  font-weight: 550;
}

.linksoft_contact2-card-name,
.linksoft_contact2-card-lname {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  width: 100%;
}

.linksoft_contact2-card-section2 {
  width: 30%;
  color: var(--color-white);
  background-color: var(--color-darkblue);
  padding: 2rem 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 3rem;
  border-radius: 1rem;
}

.linksoft_contact2-card_content {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0;
}

.linksoft_contact2-card_email-phone {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.linksoft_contact2-card-contact-info {
  text-align: center;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  /* margin-bottom: 2rem; */
  /* border: 2px solid red; */
}

.linksoft_contact2-card-icons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 1rem;
  margin-top: auto;
  /* border: 2px solid red; */
}

.linksoft_contact2-card_location {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 2px solid red; */
}

.linksoft_contact2-card_details {
  display: flex;
  font-size: 1.2rem;
  /* font-weight: 600; */
}

.linksoft_contact2-card-location-input {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  /* border: 2px solid red; */
}

.linksoft_contact2-card-location-input img {
  /* border: 2px solid red; */
  margin-top: 0.2rem;
}

.linksoft_contact2-card_desc {
  font-size: 0.8rem;
}

@media screen and (max-width: 950px) {
  .linksoft_contact2 {
    padding: 3rem;
  }

  .linksoft_contact2-card-main {
    flex-direction: column;
    width: 100%;
  }

  .linksoft_contact2-card-button {
    border: none;
    border-radius: 0.5rem;
  }

  .linksoft_contact2-card-section1 {
    padding: 1rem;
    width: 100%;
  }

  .linksoft_contact2-card-section1_title {
    font-size: 1.5rem;
    white-space: nowrap;
  }

  .linksoft_contact2-card-inputs {
    /* flex-direction: column; */
    gap: 1rem;
  }

  .linksoft_contact2-card-name {
    display: grid;
    grid-template-columns: auto;
    /* gap: 1rem; */
  }
}

@media screen and (max-width: 550px) {
  .linksoft_contact2 {
    padding: 0rem;
    /* border: 2px solid red; */
  }

  .linksoft_contact2-card-inputs {
    flex-direction: column;
    gap: 2rem;
  }

  .linksoft_contact2-card-button {
    color: var(--color-white);
    background-color: var(--color-darkblue);
    padding: 0.8rem 1rem;
    max-width: 8rem;
    border: none;
    border-radius: 0.2rem;
    margin: 1rem auto;
    font-size: 1.2rem;
  }

  .linksoft_contact2-card-section1-content {
    padding: 0rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-inline: auto;
    /* border: 2px solid red; */
  }

  .linksoft_contact2-card-button {
    color: var(--color-white);
    background-color: var(--color-darkblue);
    padding: 1rem 1rem;
    /* max-width: auto; */
    border: none;
    border-radius: 0.2rem;
    /* margin-inline:; */
    font-size: 1rem;
  }
  .linksoft_contact2-card-section2 {
    width: 95%;
    margin-inline: auto;
    padding: 2rem;
    gap: 2rem;
    border-radius: 1rem;
  }
}
