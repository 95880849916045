.recruitment_counter-main {
  display: flex;
  justify-content: space-around;
  background-color: var(--color-darkblue);
  padding: 2rem 0rem;
  color: var(--color-white);
}

.recruitment_counter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.recruitment_counter-heading {
  text-align: center;
  font-size: 2rem;
}

.recruitment_counter-paragraph {
  text-align: center;
  font-weight: 600;
  font-size: 0.9rem;
  color: var(--color-white);
}

.recruitment_hero-counter {
  background-color: var(--color-counter-bg);
  padding: 0;
  margin: 0;
}
.recruitment_counter_icon {
  font-size: 5rem;
}

@media screen and (max-width: 900px) {
  .recruitment_counter-main {
    padding: 1.5rem 0.5rem;
    gap: 1rem;
  }

  .recruitment_counter-heading {
    font-size: 1.2rem;
  }

  .recruitment_counter-paragraph {
    font-size: 0.7rem;
    font-weight: 500;
  }

  .recruitment_counter-rating_img {
    width: 1rem;
  }

  .recruitment_counter_icon {
    font-size: 2rem;
  }
}