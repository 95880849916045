.recruitment_jobdetail {
    padding: 2rem 0;
    background-color: var(--color-gray4);
    color: black;
    text-align: center;
}

.recruitment_jobdetail_content {
    padding: 2rem;
    display: grid;
    gap: 2rem;
}

.recruitment_jobdetail_desc {
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    line-height: 1.7;
    letter-spacing: 0.01rem;
}
.recruitment_jobdetail_desc1 {
    font-size: 2rem;
    font-weight: 700;
    text-align: start;
    line-height: 2;
    letter-spacing: 0.01rem;
    text-decoration: underline;
    
}

.recruitment_jobdetail_title {
    font-size: 2rem;
    font-weight: 600;
    text-align: start;

}

.recruitment_jobdetail_img {
    width: 80%;
    margin: 3rem 0;
    
}

@media screen and (min-width: 950px) {
    .recruitment_jobdetail {
        margin: 2rem;
        padding: 2rem 4rem;
        display: flex;
        flex-direction: row;
        border-radius: 0.5rem;
        gap: 5rem;
    }

    .recruitment_jobdetail_content {
        padding: 0rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
    }

    .recruitment_jobdetail_desc {
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 2;
    }

    .recruitment_jobdetail_title {
        font-size: 3rem;
        font-weight: 600;
    }

    .recruitment_jobdetail_btn {
        background-color: var(--color-darkblue);
        margin-inline-end: auto;
        padding: 1rem 3rem;
        color: var(--color-white);
        display: flex;
        align-items: center;
        gap: 1rem;
        border-radius: 0.3rem;
        cursor: pointer;
        font-size: 1.5rem;
    }

    .recruitment_jobdetail_img {
        width: 80%;
        margin: 3rem 0;
        
    }


}