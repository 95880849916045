.recruitment_navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3.5rem;
}

.recruitment_navbar_logo {
  font-size: 2.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  z-index: 50;
  width: fit-content;
}
.recruitment_navbar_logo img {
  width: 15rem;
}

.recruitment_navbar-links_li :link {
  color: var(--color-navbar-links);
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 0.9rem;
}

.recruitment_navbar-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recruitment_navbar-links_li:hover {
  color: var(--color-navy);
}

.recruitment_navbar-links_container {
  display: flex;
  align-items: center;
  margin-inline: auto;
  gap: 2rem;
}

.recruitment_navbar-links_login-sign_div {
  display: flex;
}

.active {
  border-bottom: 2px solid var(--color-gray1);
}

.recruitment_navbar-menu {
  display: none;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .recruitment_navbar {
    padding: 1rem;
  }

  .recruitment_navbar-links_logo_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .recruitment_navbar-links_container {
    display: none;
  }

  .recruitment_navbar-links_login-sign_div {
    display: none;
  }

  .recruitment_navbar-menu {
    display: flex;
    flex-direction: column;
  }

  .recruitment_navbar-menu_container-links {
    position: absolute;
    left: -1.3rem;
    margin: 0.5rem auto;
    background-color: var(--color-white);
    padding: 1rem 1rem;
    border-radius: 0.4rem;
    z-index: 15;
    width: 99vw;
    box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.19);
  }

  li {
    text-align: left;
    color: var(--color-text);
    font-family: var(--font-family);
    padding: 0.5rem 0rem;
    font-weight: bold;
  }

  .recruitment_navbar_name {
    font-size: 1.6rem;
  }

  .recruitment_navbar-menu_toggleBtn {
    font-size: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .recruitment_navbar-menu {
    margin-left: 0.5rem;
  }
}
