.recruitment_jobcard {
    padding: 1rem;
    display: grid;
    gap: 0.8rem;
}

.recruitment_jobcard_cards-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 1rem;
}

.recruitment_jobcard_card {
    border: 2px solid var(--color-gray4);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    gap: 0.5rem;
}

.recruitment_jobcard_card_title {
    font-size: 0.9rem;
    font-weight: 600;
}

.recruitment_jobcard_card_desc {
    font-size: 0.6rem;

}

.recruitment_jobcard_card_btn {
    background: none;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.recruitment_jobcard_card:hover {
    border: 2px solid var(--color-gray1);
    background-color: var(--color-gray4);
}

@media screen and (min-width: 950px) {

    .recruitment_jobcard {
        padding: 3rem;
        display: grid;
        text-align: center;
        gap: 0.8rem;
    }

    .recruitment_jobcard_cards-div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;
        align-items: center;
        gap: 1rem;
       margin: 3rem;
    }

    .recruitment_jobcard_card {
        border: 2px solid var(--color-gray4);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1rem;
        gap: 1rem;
        width: 60%;
    }

    .recruitment_jobcard_card_title {
        font-size: 2rem;
        font-weight: 800;
        line-height: 80px;
    }

    .recruitment_jobcard_card_desc {
        font-size: 1rem;
        font-weight: 400;
        line-height: 30px;

    }

    .recruitment_jobcard_card_btn {
        background: none;
        border: none;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .recruitment_jobcard_card:hover {
        border: 2px solid var(--color-gray1);
        background-color: var(--color-gray4);
    }
}