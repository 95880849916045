.recruitment_hero {
  display: flex;
  background-color: var(--color-gray4);
  padding-top: 2rem;
}

.recruitment_hero_content {
  width: 55%;
  padding: 3rem 0 3rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.recruitment_hero_heading {
  color: var(--color-darkblue);
  font-size: 4rem;
  font-weight: 600;
  line-height: 5rem;
}

.recruitment_hero_desc {
  color: rgba(50, 20, 116, 0.9);
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 600;
}

.recruitment_hero_img-div {
  width: 45%;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.recruitment_hero_img {
  width: 80%;
  margin: 0 auto;
  margin-top: auto;
  z-index: 2;
}

.recruitment_hero_imgBg {
  width: 70%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.recruitment_hero_search_main {
  display: flex;
  align-items: center;
  width: 100%;
}

.recruitment_hero_search_div {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  padding: 1rem;
  height: 4rem;
  font-size: 3rem;
  gap: 0.5rem;
  width: 70%;
}

.recruitment_hero_search_div input {
  border: none;
  outline: none;
  font-size: 1rem;
  width: 100%;
}

.recruitment_hero_search_div input:focus {
  border: none;
  outline: none;
}

.recruitment_hero_search_main button {
  background-color: var(--color-darkblue);
  width: 30%;
  height: 100%;
  color: var(--color-white);
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  .recruitment_hero {
    flex-direction: column;
    gap: 2rem;
  }

  .recruitment_hero_content {
    width: 100%;
    padding: 1rem;
    gap: 1.5rem;
  }

  .recruitment_hero_heading {
    font-size: 2rem;
    line-height: 1.5;
  }

  .recruitment_hero_desc {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  .recruitment_hero_img-div {
    width: 100%;
  }

  .recruitment_hero_search_main {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .recruitment_hero_search_div {
    height: 3rem;
  }

  .recruitment_hero_search_div input {
    font-weight: 600;
  }

  .recruitment_hero_search_main button {
    height: 3rem;
  }
}

@media screen and (max-width: 550px) {
  .recruitment_hero {
    flex-direction: column;
    gap: 2rem;
  }

  .recruitment_hero_content {
    width: 100%;
    padding: 1rem;
    gap: 1.5rem;
  }

  .recruitment_hero_heading {
    font-size: 2rem;
    line-height: 1.5;
  }

  .recruitment_hero_desc {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  .recruitment_hero_img-div {
    width: 100%;
  }

  .recruitment_hero_search_main {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .recruitment_hero_search_div {
    height: 3rem;
  }

  .recruitment_hero_search_div input {
    font-weight: 600;
  }

  .recruitment_hero_search_main button {
    height: 3rem;
  }
}
