@import url("https://fonts.googleapis.com/css2?family=Kanit&family=Lato:wght@300;400;700;900&family=Pacifico&family=Permanent+Marker&family=Poppins:ital,wght@0,100;0,200;0,300;0,600;0,700;1,200;1,400;1,500;1,600&family=Uchen&display=swap");

:root {
  --font-family: "Poppins";
  --color-darkblue: #1F0D46;
  --color-blue: #713EDD;
  --color-navy: #321474E5;
  --color-gray1: #333333;
  --color-gray2: #F2F2F2;
  --color-gray3: #888888;
  --color-gray4: #F1E9FF;
  --color-white: #FEFEFE;
  --color-yellow: rgb(255, 217, 0);
  --color-dark-yellow: #FFB700;


}